<template>
  <div style="height: 1000px">
    报表详情
    <el-button @click="sendError">发出一个错误的请求</el-button>
  </div>
</template>

<script>
import { error_get } from 'src/api'

export default {
  name: 'report-detail',
  methods: {
    sendError() {
      return error_get()
    },
  },
}
</script>

<style scoped></style>
